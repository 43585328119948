import React, {
    ChangeEvent, useCallback, useEffect, useState,
} from 'react';
import { ExtractRouteParams, match as routerMatch } from 'react-router';
import Autosuggest from 'react-autosuggest';
import { take } from 'ramda';
import {
    Category, Client, EquipmentAsset, Geolocation,
} from '../types';
import NamedInput from '../controls/NamedInput';
import Loading from './Loading';
import Services from '../services';
import AppHeader from '../controls/AppHeader';
import { Actions, LabelWrapper, RenderIf } from '../controls/index';
import { State } from '../state/application';
import ImageInput from '../controls/ImageInput';
import { UNKNOWN } from '../constants';

const NUMBER_OF_SUGGEST_ITEMS_TO_DISPLAY = 10;

interface Props {
    photosCache: Record<number, string | symbol>,
    getApplicationState: () => State,
    clients: Array<Client>,
    makes: Array<string>,
    equipment: Array<EquipmentAsset>,
    categories: Array<Category>,
    locations: Array<Geolocation>,
    apiServices: Services,
    onUpdated: (asset: EquipmentAsset) => void,
    onError: (message: string) => void,
    onDeletingPhoto: (id: string) => Promise<void>,
    onPhotoAdded: (id: string, data: Blob) => void,
    onRequestingPhoto: (id: string) => void,
    loading: {
        categories: boolean,
        clients: boolean,
        equipment: boolean,
        locations: boolean,
        makes: boolean,
    },
    match: routerMatch<ExtractRouteParams<string, string>>,
}

function createEmptyEquipment(clientId: string, locationId: string | null): EquipmentAsset {
    return {
        primaryImageId: null,
        vinImageId: null,
        categoryId: null,
        clientId,
        make: null,
        model: null,
        horsepower: null,
        internalIdentifier: null,
        currentLocationId: locationId,
        name: '',
        vin: '',
        year: null,
        axles: [],
    };
}

enum WhichPhoto {
    Primary,
    Vin,
}

export default function EquipmentEdit(props: Props) {
    const {
        makes,
        photosCache,
        getApplicationState,
        equipment,
        clients,
        loading,
        apiServices,
        categories,
        locations,
        onUpdated,
        onError,
        onPhotoAdded,
        onDeletingPhoto,
        onRequestingPhoto,
        match: {
            params: {
                clientId,
                equipmentId,
                locationId,
            },
        },
    } = props;

    const isNew = equipmentId === 'new';
    const selectedClient = loading.clients ? undefined : clients.find((items) => `${items.id}` === clientId);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [initializedAsset, setInitializedAsset] = useState<string | null>(null);
    const [categoryId, setCategoryId] = useState<string | null>(null);
    const [make, setMake] = useState<string | null>(null);
    const [model, setModel] = useState<string | null>(null);
    const [vin, setVin] = useState<string | null>(null);
    const [
        internalIdentifier,
        setInternalIdentifier,
    ] = useState<string | null>(null);
    const [year, setYear] = useState<number | null>(null);
    const [hp, setHp] = useState<number | null>(null);
    const [primaryImageId, setPrimaryImageId] = useState<string | null>(null);
    const [vinImageId, setVinImageId] = useState<string | null>(null);
    const [selectedLocation, setSelectedLocation] = useState<string | null>(null);
    const [imagesToDelete, setImagesToDelete] = useState<Array<string>>([]);
    const [filteredMakes, setFilteredMakes] = useState<Array<string>>([]);

    useEffect(() => {
        if (!initializedAsset && !loading.clients && !loading.equipment) {
            const selectedEquipmentAsset = isNew
                ? createEmptyEquipment(
                    clientId ?? UNKNOWN,
                    locationId ?? null,
                )
                : equipment.find(
                    (asset) => `${asset.id}` === equipmentId
                        && selectedClient?.id === asset.clientId,
                );

            if (!selectedEquipmentAsset) {
                throw new Error('Should not happen.');
            }

            if (selectedEquipmentAsset.primaryImageId) {
                onRequestingPhoto(selectedEquipmentAsset.primaryImageId);
            }
            if (selectedEquipmentAsset.vinImageId) {
                onRequestingPhoto(selectedEquipmentAsset.vinImageId);
            }

            setCategoryId(selectedEquipmentAsset.categoryId ?? null);
            setMake(selectedEquipmentAsset.make || '');
            setModel(selectedEquipmentAsset.model || '');
            setVin(selectedEquipmentAsset.vin || '');
            setInternalIdentifier(selectedEquipmentAsset.internalIdentifier || '');
            setYear(selectedEquipmentAsset.year || new Date().getFullYear());
            setHp(selectedEquipmentAsset.horsepower || 0);
            setInitializedAsset(selectedEquipmentAsset.id ?? null);
            setPrimaryImageId(selectedEquipmentAsset?.primaryImageId ?? null);
            setVinImageId(selectedEquipmentAsset?.vinImageId ?? null);
            setSelectedLocation(selectedEquipmentAsset?.currentLocationId);
        }
    }, [apiServices, categories, clientId, equipment, equipmentId, initializedAsset, isNew, loading, locationId, onError, onRequestingPhoto, selectedClient]);

    const completionRoute = isNew
        ? `/clients/${clientId}/equipment`
        : `/clients/${clientId}/equipment/${equipmentId}`;

    const handleSubmission = useCallback((event) => {
        event.preventDefault();

        if (!initializedAsset && !isNew) {
            throw new Error('Should be unreachable');
        }

        const selectedEquipmentAsset = isNew
            ? createEmptyEquipment(
                clientId ?? UNKNOWN,
                locationId ?? UNKNOWN,
            )
            : equipment.find(
                (asset) => `${asset.id}` === equipmentId
                        && selectedClient?.id === asset.clientId,
            );

        const upsertMethod = (isNew
            ? apiServices.createEquipmentAsset
            : apiServices.updateEquipmentAsset
        ).bind(apiServices);

        const name = [
            make,
            model,
            internalIdentifier ? `(${internalIdentifier})` : null,
        ]
            .filter((x) => x)
            .join(' ');

        upsertMethod({
            ...(selectedEquipmentAsset as EquipmentAsset),
            categoryId,
            vin,
            internalIdentifier,
            year,
            make,
            name,
            model,
            horsepower: hp,
            primaryImageId,
            vinImageId,
            currentLocationId: selectedLocation,
        })
            .then((asset) => {
                onUpdated(asset);
                imagesToDelete.forEach(onDeletingPhoto);
                getApplicationState()
                    .appHistory
                    .push(completionRoute);
            })
            .catch((e) => onError(e.message));
    },
    [
        onError,
        initializedAsset,
        isNew,
        clientId,
        locationId,
        equipment,
        apiServices,
        make,
        model,
        internalIdentifier,
        categoryId,
        vin,
        year,
        hp,
        primaryImageId,
        vinImageId,
        selectedLocation,
        equipmentId,
        selectedClient,
        onUpdated,
        imagesToDelete,
        onDeletingPhoto,
        getApplicationState,
        completionRoute,
    ]);

    const onCancel = useCallback(() => {
        getApplicationState()
            .appHistory
            .replace(completionRoute);
    }, [completionRoute, getApplicationState]);

    const handleLocationChanged = (event: ChangeEvent<HTMLSelectElement>) => {
        setSelectedLocation(event.target.value ? event.target.value : null);
    };

    const handleCategoryChosen = useCallback((event) => {
        setCategoryId(event.target.value ? event.target.value : null);
    }, []);

    if (loading.clients || loading.equipment || loading.categories || loading.locations || loading.makes) {
        return <Loading />;
    }

    const matchSuggestionBySubstring = (value: string) => makes.filter(
        (item) => {
            if (value.length === 0) {
                return item;
            }
            return item.toLowerCase().includes(value.toLowerCase());
        },
    );

    const isMakeSelectionValid = (make?.length ?? 0) === 0
    || makes.includes(make ?? '(No make selection)');
    const isValid = isMakeSelectionValid;

    if (!selectedClient || (!initializedAsset && !isNew)) {
        // TODO: Show something more meaningful here.  Hopefully this is unreachable, but
        // we should be prepared for it regardless...
        return null;
    }

    function handlePhotoChosen(which: WhichPhoto, replaces: string | null) {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files?.length) {
                const chosenFile = event.target.files[0];

                if (chosenFile) {
                    apiServices.uploadPhoto(chosenFile)
                        .then((imageId) => {
                            onPhotoAdded(imageId, chosenFile);

                            switch (which) {
                                case WhichPhoto.Primary:
                                    setPrimaryImageId(imageId);
                                    break;
                                case WhichPhoto.Vin:
                                    setVinImageId(imageId);
                                    break;
                                default:
                                    throw new Error(`Which: ${which} not expected.`);
                            }

                            if (replaces) {
                                setImagesToDelete([
                                    ...imagesToDelete,
                                    replaces,
                                ]);
                            }
                        })
                        .catch((e) => onError(e.message));
                }
            }
        };
    }

    const handlePhotoRemoved = (which: WhichPhoto, photoId: string) => {
        setImagesToDelete([
            ...imagesToDelete,
            photoId,
        ]);

        if (which === WhichPhoto.Vin) {
            setVinImageId(null);
        } else {
            setPrimaryImageId(null);
        }
    };

    const selectedCategory = categories.find((cat) => cat.id === categoryId);
    const parentCategory = categories.find((cat) => cat.id === selectedCategory?.parentId);
    const categoryName = selectedCategory?.parentId
        ? `${parentCategory?.singularName} (${selectedCategory?.singularName ?? ''})`
        : selectedCategory?.singularName ?? '';

    const categoryGroups = [
        <option value="">(Please make a selection)</option>,
        ...categories
            .filter((cat) => cat.parentId === null)
            .map((cat) => {
                const childCategories = categories
                    .filter((subcat) => subcat.parentId === cat.id)
                    .map((subcat) => (
                        <option
                            className="childCategory"
                            value={subcat.id}
                        >&nbsp;&nbsp;{subcat.singularName}
                        </option>
                    ));

                return (
                    <>
                        <option value={cat.id} className="topLevelCategory">{cat.singularName}</option>
                        {childCategories}
                    </>
                );
            }),
    ];
    const locationOptions = locations
        .filter((loc) => `${loc.clientId}` === clientId)
        .map((loc) => <option value={loc.id}>{loc.name}</option>);

    const title = `${initializedAsset ? 'Edit' : 'Add'} Equipment`;

    return (
        <div className="equipmentEdit">
            <AppHeader getApplicationState={getApplicationState} title={selectedClient.name} hideBackButton={!isNew}>
                <RenderIf condition={isNew}>
                    <button onClick={handleSubmission} type="submit">{isNew ? 'Create' : 'Update'}</button>
                </RenderIf>
            </AppHeader>

            <h1>{title}</h1>

            <form method="POST">
                <div>
                    <ImageInput
                        name="equipmentPhoto"
                        label="Equipment Primary Image"
                        multiple={false}
                        onChange={handlePhotoChosen(WhichPhoto.Primary, primaryImageId)}
                        ids={primaryImageId !== null ? [primaryImageId] : []}
                        photosCache={photosCache}
                        onDelete={(photoId) => {
                            handlePhotoRemoved(WhichPhoto.Primary, photoId);
                            return Promise.resolve();
                        }}
                        isReadOnly={false}
                        addPhotoLabel="Add Asset Photo"
                    />
                </div>

                <div className="form-header">
                    <h2>{categoryName}</h2>
                </div>

                <LabelWrapper childId="namedInputcategory" label="Category">
                    <select
                        id="namedInputcategory"
                        value={categoryId ?? ''}
                        onChange={handleCategoryChosen}
                    >
                        {categoryGroups}
                    </select>
                </LabelWrapper>
                <LabelWrapper
                    label="Make"
                    childId="namedInputmakes"
                    error={isMakeSelectionValid ? undefined : 'Must be an exact match with a known option'}
                >
                    <Autosuggest
                        suggestions={filteredMakes}
                        getSuggestionValue={(value) => value}
                        renderSuggestion={(value) => <div>{value}</div>}
                        inputProps={{
                            id: 'makes',
                            placeholder: 'Make',
                            value: make ?? '',
                            onChange: (event, { newValue }) => setMake(newValue),
                        }}
                        onSuggestionsFetchRequested={({ value }) => {
                            const matchingRecords = matchSuggestionBySubstring(value);
                            setFilteredMakes(take(NUMBER_OF_SUGGEST_ITEMS_TO_DISPLAY, matchingRecords));
                        }}
                        shouldRenderSuggestions={() => true}
                    />
                </LabelWrapper>
                <NamedInput
                    type="text"
                    name="model"
                    label="Model"
                    value={model ?? ''}
                    onChange={(event) => setModel(event.target.value)}
                />
                <div
                    className="form-row"
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 10rem',
                        margin: '0 4.5rem 0 0',
                        gridGap: '3rem',
                        alignItems: 'center',
                    }}
                >
                    <NamedInput
                        type="text"
                        name="vin"
                        label="Vin"
                        value={vin || ''}
                        onChange={(event) => setVin(event.target.value)}
                    />
                    <ImageInput
                        name="vinPhoto"
                        label="VIN Image"
                        multiple={false}
                        onChange={handlePhotoChosen(WhichPhoto.Vin, vinImageId)}
                        ids={vinImageId ? [vinImageId] : []}
                        onDelete={(photoId) => {
                            handlePhotoRemoved(WhichPhoto.Vin, photoId);
                            return Promise.resolve();
                        }}
                        photosCache={photosCache}
                        isReadOnly={false}
                        addPhotoLabel="Add VIN Photo"
                    />
                </div>
                <NamedInput
                    type="text"
                    name="internalIdentifier"
                    label="ID"
                    value={internalIdentifier || ''}
                    onChange={(event) => setInternalIdentifier(event.target.value)}
                />
                <NamedInput
                    type="number"
                    name="year"
                    label="Year"
                    value={year || ''}
                    onChange={
                        (event) => setYear(parseInt(event.target.value, 10))
                    }
                />
                <NamedInput
                    type="number"
                    name="hp"
                    label="Horsepower"
                    value={hp || ''}
                    onChange={(event) => setHp(parseFloat(event.target.value))}
                />

                <LabelWrapper childId="namedInputlocation" label="Location">
                    <select
                        id="namedInputlocation"
                        value={`${selectedLocation ?? ''}`}
                        onChange={handleLocationChanged}
                    >
                        {[
                            <option value="">(No location selected)</option>,
                            ...locationOptions,
                        ]}
                    </select>
                </LabelWrapper>

                <RenderIf condition={!isNew}>
                    <Actions>
                        <button
                            onClick={handleSubmission}
                            type="submit"
                            disabled={!isValid}
                        >{isNew ? 'Create' : 'Update'}
                        </button>
                        <button className="risky" type="button" onClick={onCancel}>Cancel</button>
                    </Actions>
                </RenderIf>
            </form>
        </div>
    );
}
