import { clone, omit, uniq } from 'ramda';
import React, { ChangeEvent } from 'react';
import {
    SubjectCondition,
    TireInspection,
    TrackInspection,
    TrackTireInspection,
    TrackTireType,
} from '../../types';
import identifyInspectionSubjectType from '../../utils/identify-inspection-subject-type';
import {
    InputWrapper, LabelWrapper, NamedInput, NotesInput,
} from '../../controls/index';
import Services from '../../services';
import { convertImageBlobToDataUrl } from '../../utils/get-image-src';
import ConditionSelector from '../../controls/ConditionSelector';
import ImageInput from '../../controls/ImageInput';

export type CopySpecs = TrackInspection & TireInspection;

function mapSubjectToTire(subject: TrackTireInspection): TireInspection {
    return {
        ...omit(['trackCondition', 'undercarriageCondition'], subject),
        inflationCondition: SubjectCondition.Unknown,
        inflationConditionNotes: '',
        tireCondition: SubjectCondition.Unknown,
        tireConditionNotes: '',
        wheelCondition: SubjectCondition.Unknown,
        wheelConditionNotes: '',
        wheelPartNumber: '',
    };
}
function mapSubjectToTrack(subject: TrackTireInspection): TrackInspection {
    return {
        ...omit([
            'inflationCondition',
            'inflationConditionNotes',
            'tireCondition',
            'tireConditionNotes',
            'wheelCondition',
            'wheelConditionNotes',
            'wheelPartNumber',
        ], subject),
        alignment: '',
        trackCondition: SubjectCondition.Unknown,
        undercarriageCondition: SubjectCondition.Unknown,
        trackConditionNotes: '',
        undercarriageConditionNotes: '',
    };
}

interface BaseProps {
    isReadOnly: boolean,
    selectedSubject: TrackTireInspection,
    onSubjectUpdated: (subject: TrackTireInspection, photosAdded: Record<number, string>) => void,
}

interface TrackTireDetailProps extends BaseProps {
    isReadOnly: boolean,
    apiServices: Services,
    photosCache: Record<number, string | symbol>,
    onSubjectDeleted: (subject: TrackTireInspection) => void,
    onImageDeleted: (id: string) => Promise<void>,
    setSpecs: (specs: CopySpecs) => void,
    specs: CopySpecs | null,
    justAboveTiresAndTracks: React.MutableRefObject<any>,
    onError: (message: string) => void,
}

function TireDetail(props: BaseProps) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { isReadOnly, selectedSubject, onSubjectUpdated } = props;
    const tireSubject = selectedSubject as TireInspection;
    const updateSubject: (
        toUpdate: object,
        photosAdded: Record<number, string>
    ) => void = (toUpdate, photosAdded) => onSubjectUpdated({
        ...clone(tireSubject),
        ...toUpdate,
    } as TrackTireInspection, photosAdded);

    function updateTireCondition(condition: SubjectCondition) {
        updateSubject({ tireCondition: condition }, {});
    }
    function updateInflationCondition(condition: SubjectCondition) {
        updateSubject({ inflationCondition: condition }, {});
    }
    function updateWheelCondition(condition: SubjectCondition) {
        updateSubject({ wheelCondition: condition }, {});
    }
    const handleWheelPartNumberChanged = isReadOnly
        ? undefined
        : (event: ChangeEvent<HTMLTextAreaElement>) => updateSubject(
            { wheelPartNumber: event.target.value },
            {},
        );
    const handleInflationConditionNotesChanged = isReadOnly
        ? undefined
        : (event: ChangeEvent<HTMLTextAreaElement>) => onSubjectUpdated({
            ...clone(tireSubject),
            inflationConditionNotes: event.target.value,
        } as TrackTireInspection, {});
    const handleTireConditionNotesChanged = isReadOnly
        ? undefined
        : (event: ChangeEvent<HTMLTextAreaElement>) => onSubjectUpdated({
            ...clone(tireSubject),
            tireConditionNotes: event.target.value,
        } as TrackTireInspection, {});
    const handleWheelConditionNotesChanged = isReadOnly
        ? undefined
        : (event: ChangeEvent<HTMLTextAreaElement>) => onSubjectUpdated({
            ...clone(tireSubject),
            wheelConditionNotes: event.target.value,
        } as TrackTireInspection, {});

    return (
        <>
            <NamedInput
                type="text"
                name="wheelPartNumber"
                label="Wheel Part Number"
                value={tireSubject.wheelPartNumber}
                onChange={handleWheelPartNumberChanged}
            />
            <ConditionSelector
                isReadOnly={isReadOnly}
                onChange={updateInflationCondition}
                value={tireSubject.inflationCondition}
                label="Inflation Condition"
                id="namedInputinflationCondition"
            >
                <NotesInput
                    name="inflationCondition"
                    label="Inflation Condition Notes"
                    showFieldLabel={false}
                    value={tireSubject.inflationConditionNotes}
                    onChange={handleInflationConditionNotesChanged}
                />
            </ConditionSelector>
            <ConditionSelector
                isReadOnly={isReadOnly}
                onChange={updateTireCondition}
                value={tireSubject.tireCondition}
                label="Tire Condition"
                id="namedInputtireCondition"
            >
                <NotesInput
                    name="tireCondition"
                    label="Tire Condition Notes"
                    showFieldLabel={false}
                    value={tireSubject.tireConditionNotes}
                    onChange={handleTireConditionNotesChanged}
                />
            </ConditionSelector>
            <ConditionSelector
                isReadOnly={isReadOnly}
                onChange={updateWheelCondition}
                value={tireSubject.wheelCondition}
                label="Wheel Condition"
                id="namedInputwheelCondition"
            >
                <NotesInput
                    name="wheelCondition"
                    label="Wheel Condition Notes"
                    showFieldLabel={false}
                    value={tireSubject.wheelConditionNotes}
                    onChange={handleWheelConditionNotesChanged}
                />
            </ConditionSelector>
        </>
    );
}

function TrackDetail(props: BaseProps) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { selectedSubject, onSubjectUpdated, isReadOnly } = props;
    const trackSubject = selectedSubject as TrackInspection;
    const updateSubject: (
        toUpdate: object,
        photosAdded: Record<number, string>,
    ) => void = (toUpdate, photosAdded) => onSubjectUpdated({
        ...clone(trackSubject),
        ...toUpdate,
    }, photosAdded);

    function updateUndercarriageCondition(condition: SubjectCondition) {
        updateSubject({ undercarriageCondition: condition }, {});
    }
    function updateTrackCondition(condition: SubjectCondition) {
        updateSubject({ trackCondition: condition }, {});
    }

    const handleAlignmentNotesChanged = isReadOnly
        ? undefined
        : (event: ChangeEvent<HTMLTextAreaElement>) => updateSubject(
            { alignment: event.target.value },
            {},
        );
    const handleTrackConditionNotesChanged = isReadOnly
        ? undefined
        : (event: ChangeEvent<HTMLTextAreaElement>) => onSubjectUpdated({
            ...clone(trackSubject),
            trackConditionNotes: event.target.value,
        } as TrackTireInspection, {});
    const onUnderCarriageConditionNotesChanged = isReadOnly
        ? undefined
        : (event: ChangeEvent<HTMLTextAreaElement>) => onSubjectUpdated({
            ...clone(trackSubject),
            undercarriageConditionNotes: event.target.value,
        } as TrackTireInspection, {});

    return (
        <>
            <NotesInput
                name="alignment"
                label="Alignment"
                value={trackSubject.alignment}
                severity
                onChange={handleAlignmentNotesChanged}
            />
            <ConditionSelector
                isReadOnly={isReadOnly}
                onChange={updateTrackCondition}
                label="Track Condition Notes"
                id="namedInputtrackCondition"
                value={trackSubject.trackCondition}
            >
                <NotesInput
                    name="trackCondition"
                    label="Track Condition Notes"
                    showFieldLabel={false}
                    value={trackSubject.trackConditionNotes}
                    onChange={handleTrackConditionNotesChanged}
                />
            </ConditionSelector>
            <ConditionSelector
                isReadOnly={isReadOnly}
                onChange={updateUndercarriageCondition}
                value={trackSubject.undercarriageCondition}
                label="Undercarriage Condition"
                id="namedInputundercarriageCondition"
            >
                <NotesInput
                    name="undercarriageCondition"
                    label="Undercarriage Condition Notes"
                    showFieldLabel={false}
                    value={trackSubject.undercarriageConditionNotes}
                    onChange={onUnderCarriageConditionNotesChanged}
                />
            </ConditionSelector>
        </>
    );
}

export default function TrackTireDetail(props: TrackTireDetailProps) {
    const {
        isReadOnly,
        selectedSubject,
        onSubjectUpdated,
        onSubjectDeleted,
        onImageDeleted,
        apiServices,
        setSpecs,
        specs,
        photosCache,
        justAboveTiresAndTracks,
        onError,
    } = props;
    const subjectType = identifyInspectionSubjectType(selectedSubject);

    const setTireSelection = () => onSubjectUpdated(
        mapSubjectToTire(clone(selectedSubject)),
        {},
    );
    const setTrackSelection = () => onSubjectUpdated(
        mapSubjectToTrack(clone(selectedSubject)),
        {},
    );

    const typeSpecificDetail = subjectType === TrackTireType.Tire
        ? (
            <TireDetail
                isReadOnly={isReadOnly}
                selectedSubject={selectedSubject}
                onSubjectUpdated={onSubjectUpdated}
            />
        )
        : (
            <TrackDetail
                isReadOnly={isReadOnly}
                selectedSubject={selectedSubject}
                onSubjectUpdated={onSubjectUpdated}
            />
        );

    const copySpecs = () => {
        setSpecs(selectedSubject as CopySpecs);

        // @ts-ignore
        justAboveTiresAndTracks?.current?.scrollIntoView({ behavior: 'smooth' });
    };
    const pasteSpecs = () => {
        if (specs) {
            const {
                brand,
                size,
                year,
                treadDepth,
                wheelPartNumber,
                inflationCondition,
                inflationConditionNotes,
                tireCondition,
                tireConditionNotes,
                wheelCondition,
                wheelConditionNotes,
                alignment,
                trackCondition,
                trackConditionNotes,
                undercarriageCondition,
                undercarriageConditionNotes,
            } = specs;
            const tireProps = {
                wheelPartNumber,
                tireCondition,
                tireConditionNotes,
                wheelCondition,
                wheelConditionNotes,
                inflationCondition,
                inflationConditionNotes,
            };
            const trackProps = {
                alignment,
                trackCondition,
                trackConditionNotes,
                undercarriageCondition,
                undercarriageConditionNotes,
            };

            onSubjectUpdated({
                ...selectedSubject,
                ...tireProps,
                ...trackProps,
                brand,
                size,
                year,
                treadDepth,
            }, {});
        }
    };
    const deleteButton = (
        <button
            type="button"
            disabled={isReadOnly}
            className="risky"
            onClick={() => onSubjectDeleted(selectedSubject)}
        >{`Delete ${subjectType}`}
        </button>
    );

    const handleImagesChosen = (event: ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(event.target.files ?? []);
        const uploads = files.map(
            (img) => apiServices
                .uploadPhoto(img)
                .then((photoId) => convertImageBlobToDataUrl(img)
                    .then((imageSrc) => ({ [photoId]: imageSrc } as Record<string, string>))),
        );

        Promise.all(uploads)
            .then((bindingResults) => {
                const imageIdValues = bindingResults.reduce(
                    (memo, record) => [
                        ...memo,
                        ...Object.keys(record),
                    ], [] as Array<string>,
                );
                const allImageIdValues: Array<string> = [
                    ...selectedSubject.images,
                    ...imageIdValues,
                ];
                const uniqueImages = uniq(allImageIdValues);

                const allUploadedImagesById = bindingResults.reduce(
                    (memo, current) => ({
                        ...memo,
                        ...current,
                    }),
                    {},
                );

                onSubjectUpdated({
                    ...clone(selectedSubject),
                    images: uniqueImages,
                }, allUploadedImagesById);
            })
            .catch((e) => onError(e.message));
    };
    const handleBrandChanged = (event: ChangeEvent<HTMLInputElement>) => onSubjectUpdated({
        ...clone(selectedSubject),
        brand: event.target.value,
    }, {});
    const handleSizeChanged = (event: ChangeEvent<HTMLInputElement>) => {
        onSubjectUpdated({
            ...clone(selectedSubject),
            size: event.target.value,
        }, {});
    };
    const yearChanged = (event: ChangeEvent<HTMLInputElement>) => {
        onSubjectUpdated({
            ...clone(selectedSubject),
            year: parseInt(event.target.value, 10),
        }, {});
    };
    const treadDepthChanged = (event: ChangeEvent<HTMLInputElement>) => onSubjectUpdated({
        ...clone(selectedSubject),
        treadDepth: event.target.value,
    }, {});
    const toggleStatus = (event: ChangeEvent<HTMLInputElement>) => {
        onSubjectUpdated({
            ...clone(selectedSubject),
            completionStatus: event.target.checked,
        }, {});
    };
    const handleImageDeletion = (id: string) => onImageDeleted(id)
        .then(() => {
            const photosMinusRemoved = selectedSubject.images.filter((item) => item !== id);

            onSubjectUpdated({
                ...clone(selectedSubject),
                images: photosMinusRemoved,
            }, {});
        });

    const pasteSpecsButton = specs && (
        <button
            disabled={isReadOnly}
            type="button"
            onClick={pasteSpecs}
        >Paste Specs
        </button>
    );

    return (
        <div className="detail">
            <section className="subjectTypeSelection">
                <InputWrapper className={undefined}>
                    <NamedInput
                        type="radio"
                        value={subjectType === TrackTireType.Tire}
                        name="subjectTypeTire"
                        label="Tire"
                        onChange={!isReadOnly ? () => setTireSelection() : undefined}
                    />
                    <NamedInput
                        type="radio"
                        value={subjectType === TrackTireType.Track}
                        name="subjectTypeTrack"
                        label="Track"
                        onChange={!isReadOnly ? () => setTrackSelection() : undefined}
                    />
                </InputWrapper>
            </section>
            <NamedInput
                name="isComplete"
                type="checkbox"
                label={`${TrackTireType[subjectType]} Complete`}
                value={selectedSubject.completionStatus}
                onChange={!isReadOnly ? toggleStatus : undefined}
            />
            <NamedInput
                type="text"
                value={selectedSubject.brand || ''}
                name="brand"
                label="Brand"
                onChange={!isReadOnly ? handleBrandChanged : undefined}
            />
            <NamedInput
                type="text"
                value={selectedSubject.size || ''}
                name="size"
                label="Size"
                onChange={!isReadOnly ? handleSizeChanged : undefined}
            />
            <NamedInput
                type="number"
                value={selectedSubject.year || ''}
                name="year"
                label="Year"
                onChange={!isReadOnly ? yearChanged : undefined}
            />
            <NamedInput
                type="text"
                value={selectedSubject.treadDepth || ''}
                name="treadDepth"
                label="Tread Depth"
                onChange={!isReadOnly ? treadDepthChanged : undefined}
            />

            {typeSpecificDetail}

            <LabelWrapper childId="photos" label="Photos">
                <ImageInput
                    name="tireTrackPhotos"
                    label="Tire/Track Photos"
                    multiple
                    onChange={!isReadOnly ? handleImagesChosen : undefined}
                    isReadOnly={isReadOnly}
                    ids={selectedSubject.images}
                    photosCache={photosCache}
                    onDelete={handleImageDeletion}
                />
            </LabelWrapper>

            <div className="actions">
                <button type="button" onClick={copySpecs} disabled={isReadOnly}>Copy Specs</button>
                {pasteSpecsButton}
                {deleteButton}
            </div>
        </div>
    );
}
