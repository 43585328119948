import React, {
    ChangeEventHandler,
    ReactElement,
    useCallback,
} from 'react';

import classNames from 'classnames';
import { Unset } from '../constants';
import EditableImageWithOverlay from './EditableImageWithOverlay';
import { RenderIf } from '.';

const DEFAULT_IMAGE_INPUT_PROPS = { className: '', addPhotoLabel: 'Add Photo?' };

interface ImageInputProps {
    isReadOnly: boolean,
    className?: string,
    name: string,
    label: string,
    multiple: boolean,
    onChange: ChangeEventHandler<HTMLInputElement> | undefined,
    onDelete: (imageId: string) => Promise<void>,
    ids: Array<string>,
    photosCache: Record<string, string | symbol>,
    addPhotoLabel?: string,
}

export default function ImageInput(props: ImageInputProps): ReactElement {
    const {
        className: initialClassName,
        isReadOnly,
        name,
        label,
        onChange: initOnChange,
        onDelete,
        multiple,
        ids,
        photosCache,
        addPhotoLabel,
    } = props;

    const id = `imageInput${name}`;

    const className = classNames(
        'field',
        id,
        'imageInputWrapper',
        initialClassName,
        multiple ? 'multipleLayout' : 'singleLayout',
        { disabled: initOnChange === undefined },
    );

    const memoizedImageCallback = useCallback(
        (event) => {
            if (initOnChange) {
                initOnChange(event);
            }
        },
        [initOnChange],
    );

    const validImages = ids.filter((photoId) => photosCache[photoId] && photosCache[photoId] !== Unset);

    if (multiple) {
        const controlledImages = validImages.map((photoId) => (
            <EditableImageWithOverlay
                onDelete={() => onDelete(photoId)}
                alt={`${label}`}
                src={photosCache[photoId] as string}
            />
        ));

        return (
            <div className={className}>
                <label htmlFor={id}>
                    {isReadOnly ? undefined : (
                        <>
                            <span className="photobutton">{addPhotoLabel}</span>
                            <input
                                style={{ visibility: 'hidden', position: 'absolute' }}
                                accept="image/gif, image/jpeg, image/png"
                                type="file"
                                id={id}
                                multiple={multiple}
                                onChange={memoizedImageCallback}
                                className={className}
                            />
                        </>
                    )}
                </label>

                {controlledImages.length > 0 && (
                    <div className="images-list">
                        {controlledImages}
                    </div>
                )}
            </div>
        );
    }

    const controlledImageId = validImages[0];
    const handleRemoveClicked = () => {
        // eslint-disable-next-line no-alert
        if (window.confirm('Are you sure?')) {
            onDelete(controlledImageId);
        }
    };

    return (
        <div className={className}>
            <label htmlFor={id}>
                <input
                    style={{ visibility: 'hidden', position: 'absolute' }}
                    accept="image/gif, image/jpeg, image/png"
                    type="file"
                    id={id}
                    multiple={multiple}
                    onChange={memoizedImageCallback}
                    className={className}
                />
                {controlledImageId
                    ? (
                        <>
                            <img className="controlled-image" alt={`${label}`} src={photosCache[controlledImageId] as string} />
                            <span className="photobutton">Replace Photo</span>
                        </>
                    )
                    : (
                        <>
                            <button type="button" className="image-placeholder">{addPhotoLabel}</button>
                        </>
                    )}
            </label>
            <RenderIf condition={Boolean(controlledImageId)}>
                <button
                    type="button"
                    className="removePhoto"
                    onClick={handleRemoveClicked}
                >Remove photo
                </button>
            </RenderIf>
        </div>
    );
}
ImageInput.defaultProps = DEFAULT_IMAGE_INPUT_PROPS;
